import {createRouter, createWebHistory} from 'vue-router';
import qs from 'qs'

// import DashboardSettings from '../components/routes/DashboardSettings'
import Applayouts from '../components/layouts/app.layouts.vue'
import Dashboardlayouts from '../components/layouts/dashboard.layouts.vue'
 const routes= [
    {
        path:"/",
        name:"/",
        component: Applayouts,
        children:[
            {
                path: "/",
                name: "login",
                component:()=>import('../components/pages/auth/login.page')
            },
        ]
    },
    {
        path: "/",
        name: "page",
        component: Dashboardlayouts,
        meta: {
            required: true,
            roles: ["user", "superadmin", "admin","guardia"],
        },
        children:[
            {
                path: "/dashboard",
                name: "dashboard",
                component:()=>import('../components/pages/admin/dashboard.page.vue'),
                meta: {
                    required: true,
                    roles: ["user", "superadmin","distribution", "admin","guardia","almacen","bus","files"],
                },
            },
            {
                path: "/bus",
                name: "bus",
                component:()=>import('../components/pages/admin/bus/bus.page.vue'),
                meta: {
                    required: true,
                    roles: ["bus", "superadmin",],
                },
            },
            {
                path: "/inventory/category",
                name: "inventoryCategory",
                component:()=>import('../components/pages/admin/inventory/index.page.vue'),
                meta: {
                    required: true,
                    roles: ["inventory", "superadmin",],
                },
            },
            {
                path: "/bus/register",
                name: "bus-register",
                component:()=>import('../components/pages/admin/bus/register.page.vue'),
                meta: {
                    required: true,
                    roles: ["bus", "superadmin",],
                },
            },
            {
                path: "/almacen",
                name: "almacen",
                component:()=>import('../components/pages/admin/almacen/almacen.page.vue'),
                meta: {
                    required: true,
                    roles: ["almacen", "superadmin",],
                },
            },
            {
                path: "/almacen/categoria",
                name: "almacen/categoria",
                component:()=>import('../components/pages/admin/almacen/categoria.page.vue'),
                meta: {
                    required: true,
                    roles: ["almacen", "superadmin",],
                },
            },
            {
                path: "/almacen/equipos",
                name: "almacen/equipos",
                component:()=>import('../components/pages/admin/almacen/equipos.page.vue'),
                meta: {
                    required: true,
                    roles: ["almacen", "superadmin",],
                },
            },
            {
                path: "/almacen/cesado",
                name: "almacen/cesado",
                component:()=>import('../components/pages/admin/almacen/cesado.page.vue'),
                meta: {
                    required: true,
                    roles: ["almacen", "superadmin",],
                },
            },
            {
                path: "/user",
                name: "user",
                component:()=>import('../components/pages/admin/user.page.vue'),
                meta: {
                    required: true,
                    roles: ["user", "superadmin",],
                },
            },
            {
                path: "/category",
                name: "category",
                component:()=>import('../components/pages/admin/category.page.vue'),
                meta: {
                    required: true,
                    roles: ["user", "superadmin", ],
                },
            },
            {
                path: "/distribution",
                name: "distribution",
                component:()=>import('../components/pages/admin/distribution.page.vue'),
                meta: {
                    required: true,
                    roles: ["user", "superadmin","distribution", "guardia"],
                },
            },
            {
                path: "/labor",
                name: "labor",
                component:()=>import('../components/pages/admin/labor.page.vue'),
                meta: {
                    required: true,
                    roles: ["user", "superadmin","distribution" ,"guardia"],
                },
            },
            
            {
                path: "/personal-contract",
                name: "personal-contract",
                component:()=>import('../components/pages/admin/personalContract.page.vue'),
                meta: {
                    required: true,
                    roles: ["user", "superadmin", ],
                },
            },
            {
                path: "/spreadsheet",
                name: "spreadsheet",
                component:()=>import('../components/pages/admin/spreadsheet.page.vue'),
                meta: {
                    required: true,
                    roles: ["user", "superadmin", ],
                },
            },
            {
                path: "/advances",
                name: "advances",
                component:()=>import('../components/pages/admin/advances.page.vue'),
                meta: {
                    required: true,
                    roles: ["user", "superadmin", ],
                },
            },
            {
                path: "/reintegro",
                name: "reintegro",
                component:()=>import('../components/pages/admin/reintegroPage.vue'),
                meta: {
                    required: true,
                    roles: ["user", "superadmin", ],
                },
            },
            
            {
                path: "/distribution-create",
                name: "distribution-create",
                component:()=>import('../components/pages/admin/distributionCreate.page.vue'),
                meta: {
                    required: true,
                    roles: ["user", "superadmin", ],
                },
            },
            {
                path: "/tareo",
                name: "tareo",
                component:()=>import('../components/pages/admin/tareo.page.vue'),
                meta: {
                    required: true,
                    roles: ["user", "superadmin", ],
                },
            },
            {
                path: "/absence",
                name: "absence",
                component:()=>import('../components/pages/admin/absence.page.vue'),
                meta: {
                    required: true,
                    roles: ["user", "superadmin", ],
                },
            },
            {
                path: "/medical-rest",
                name: "medicalRest",
                component:()=>import('../components/pages/admin/absence.page.vue'),
                meta: {
                    required: true,
                    roles: ["user", "superadmin", ],
                },
            },
            {
                path: "/personal",
                name: "personal",
                component:()=>import('../components/pages/admin/personal.page.vue'),
                meta: {
                    required: true,
                    roles: ["user", "superadmin", ],
                },
            },
            {
                path: "/fifth-category",
                name: "fifth-categoria",
                component:()=>import('../components/pages/admin/fifthcategory/fifthList.page.vue'),
                meta: {
                    required: true,
                    roles: ["user", "superadmin", ],
                },
            },
            {
                path: "/files",
                name: "files",
                component:()=>import('../components/pages/admin/files/list.page.vue'),
                meta: {
                    required: true,
                    roles: ["user", "superadmin", "files"],
                },
            }
        ]
    }
];

const router =createRouter(
    {
        history: createWebHistory(),
        routes,
        // parseQuery: qs.parse,
});
router.beforeEach((to, from, next) => {
    if (to.meta) {
        if(to.path=='/'){
            const token = localStorage.getItem("token") ;
            console.log(token)
            if(token){
                router.push("/dashboard");

            }
            else{
                next();
            }

        }
        else if (to.meta.required) {
            const user = JSON.parse(localStorage.getItem("user") || null);
            const token = localStorage.getItem("token") ;
            if (user) {
                if (token) {
                    if (to.meta.roles) {
                        let access = false;
                        for (let index = 0; index < to.meta.roles.length; index++) {
                            const element = to.meta.roles[index];
                            if (user.rol.includes(element)) {
                                access = true;
                            }
                        }
                        if (access) {
                            next();
                        } else {
                            router.push("/");
                        }
                    }
                    next();
                } else {
                    router.push("/");
                }
            }
            else {
                router.push("/");
            }
        }
    }
    next();
});
    
export default router;